import React from "react"
import Layout from "../components/layout/layout"
import { graphql } from "gatsby"
import TContrib from "../components/layout/multiLanguage/translateContrib"
import styled from "styled-components"
import { breakpoint } from "styled-components-breakpoint"
import LoadingImage from "../components/LoadingImage";

const Container = styled.div`
  margin-top: 50px;
  ${breakpoint(`desktop`)`
    display: flex;
  `}
`

const Text = styled.p`
  text-align: justify;
  margin-top: 0;

  > div > p:first-of-type {
    margin-top: 0;
  }

  ${breakpoint(`desktop`)`
    flex: 2;
    padding-left: 15px;
  `}
`

const ImgContainer = styled.div`
  width: 50%;
  display: inline-block;
  padding-right: 15px;
  float: left;

  ${breakpoint(`desktop`)`
    flex: 1;
  `}
`

export default function Biographie({
  data: {
    prismicBiographie: { data },
  },
  ...props
}) {
  return (
    <Layout title="Biographie" {...props}>
      <Container>
        <ImgContainer>
          <LoadingImage src={data.image.url} alt={data.image.alt} />
        </ImgContainer>
        <Text>
          <TContrib data={data} field="text" />
        </Text>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    prismicBiographie {
      data {
        text_en {
          html
        }
        text_fr {
          html
        }
        image {
          url
          alt
        }
      }
    }
  }
`
